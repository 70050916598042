
#content{
    padding: 2%;
    width: 50vw;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

p{
    color: white;
}

