@media screen and (max-width: 600px){
    #social{
        background:linear-gradient(135deg,rgba(255,255,255,0.1),rgba(255,255,255,0));
        -webkit-backdrop-filter: blur(20px);
        backdrop-filter: blur(20px);
        box-shadow: 0 8px 32px 0 rgba(0, 0, 0,0.37);
        border: 1px solid rgba(255,255,255,0.18);
        border-radius: 32px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 25vh;
        width: 95%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-evenly;
    }
}

#horloge{
    font-family:myfont;
    margin-top: 1%;
    font-size: 500%;
    color: white;
}
#date{
    font-family:myfont;
    margin: auto;
    margin-top: 5%;
    font-size: 500%;
    color: white;
}

#clock{
    display: flex;
    flex-direction: column;
    align-items: center;
}

a img {
    width: 9vw;
    margin-top: 2vh;
}

#linkedin img {
    width: 12vw;
    margin-top: -1vh;
}

#phone img{
    width: 10vw;
    margin-top: 1vh;
}

#github img {
    border-radius: 30px;
}

#social{
    background:linear-gradient(135deg,rgba(255,255,255,0.1),rgba(255,255,255,0));
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0,0.37);
    border: 1px solid rgba(255,255,255,0.18);
    border-radius: 32px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25vh;
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

