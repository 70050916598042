
img{
    width: 10vw;
}

#grid{
    display: grid;
    margin-left: auto;
    margin-right: auto;
    width: 95vw;
    margin-top: 10vh;
    grid-template-columns: repeat(auto-fit,minmax(0, min(100%/3, max(110px, 100%/5))));
    gap: 1vw;
}

#grid h1{
    text-align: center;
}

#grid div{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2vh;
    background:linear-gradient(135deg,rgba(255,255,255,0.1),rgba(255,255,255,0));
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0,0.37);
    border: 1px solid rgba(255,255,255,0.18);
    border-radius: 32px;
}