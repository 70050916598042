ul{
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

li {
    padding: 1%;
}

li:hover{
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}

a{
    text-decoration: none;
    color: white;
}